// append urls here, DO NOT remove this line

const URLS = {
  unlockCode: "/unlock-code/:code",
  viewUnlockCode: (code: string) => `/unlock-code/${code}`,
  quizzesResults: "/quizzes-results",
  home: "/",
  homeRoute: "/home",
  notfound: "/404",
  lessonsList: {
    list: "/lessons-list",
    viewLessonRoute: "/lessons-list/:id",
    viewLesson: (lesson: any) => `/lessons-list/${lesson.id}`,
  },
  viewLesson: "/view-lesson",
  blog: {
    list: "/posts",
    viewPost: (post: any) => `/posts/${post.slug}`,
    viewPostsRoute: "/posts/:slug",
  },
  dashboard: {
    main: "/dashboard",
  },
  payments: "/payments",
  fawryPaymentsStatus: "/payments/fawry/status",
  viewAnswers: "/view-answers",
  lessonQuiz: "/lesson-quiz",
  editProfile: "/edit-profile",
  contactUs: "/contact-us",
  notFound: "/404",
  updatePassword: "/update-password",
  myProfile: "/my-profile",
  quizzesList: {
    list: "/quizzes-list",
    viewQuizRoute: "/quizzes-list/:id",
    viewQuiz: (quiz: any) => `/quizzes-list/${quiz.id}`,
    // viewResult: (quiz: any) => `/quizzes-list/${quiz.id}/result`,
    viewQuestionsRoute: "/quizzes-list/:id/start",
    viewQuestions: (quiz: any) => `/quizzes-list/${quiz.id}/start`,
  },
  pages: {
    aboutUs: "/about-us",
    termsConditions: "/terms-conditions",
    returnPolicy: "/return-policy",
    viewRoute: "/pages/:slug/:id",
    view: (page: any) => `/pages/${page.slug}/${page.id}`,
  },
  auth: {
    login: "/login",
    forgetPassword: "/forget-password",
    resetPassword: "/reset-password",
    register: "/register",
    verifyForgetPassword: "/forget-password/verify",
  },
};

export default URLS;
